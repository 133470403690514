<template>
  <div>
    <a-page-header
      style="padding: 0"
      sub-title="Connect Miles"
      @back="() => $router.go(-1)"
    >
      <div slot="title">
        <h1>RELATÓRIO DE CHECK-IN</h1>
      </div>
      <div slot="extra"></div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mt-20 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="mt-5" :gutter="20">
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID do cartão</label>
              <a-input
                placeholder=""
                v-model="filters.id"
                @change="getMilesCards()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Fornecedor</label>
              <a-auto-complete
                :data-source="
                  suppliers.map(({ first_name, last_name, id }) => ({
                    value: id,
                    text: `${id} - ${first_name} ${last_name}`,
                  }))
                "
                v-model="filters.supplier"
                style="width: 100%"
                placeholder="Buscar fornecedores..."
                @search="searchSupplier"
                @change="getMilesCards()"
              />
            </div>
          </a-col>



          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Cias</label>
              <a-select
                class="travel-input"
                placeholder="Selecione a cia aérea"
                mode="multiple"
                v-model="filters.airlines.selected"
                show-search
                style="width: 100%"
                @change="getMilesCards()"
              >
                <a-select-option
                  v-for="(item, index) of filters.airlines.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Programas de Milhas</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o programa"
                mode="multiple"
                v-model="filters.milesPrograms.selected"
                show-search
                style="width: 100%"
                @change="getMilesCards()"
              >
                <a-select-option
                  v-for="(item, index) of filters.milesPrograms.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="5">
            <div class="travel-input">
              <label class="filled">Período</label>
              <a-range-picker
                v-model="filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getMilesCards()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <div class="travel-table last-itens">
      <HayaTable
        :data-source="milesCards.list"
        :loading="milesCards.loading"
        :pagination="false"
        :paginator="milesCards.pagination"
        @getList="getMilesCards(milesCards.pagination.page)"
        :columns="[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            class: 'id',
            width: 80,
          },
          {
            title: 'Fornecedor',
            dataIndex: 'supplier',
            key: 'supplier',
            scopedSlots: { customRender: 'supplier' },
          },
          {
            title: 'Pgm. Milhas',
            dataIndex: 'miles_program',
            key: 'miles_program',
          },
          {
            title: 'Total Milhas',
            dataIndex: 'total_miles',
            key: 'total_miles',
            class: 'total-miles',
          },
          {
            title: 'Disponível',
            dataIndex: 'available_miles',
            key: 'available_miles',
            class: 'available-miles',
          },
          {
            title: 'Utilizado',
            dataIndex: 'spent_miles',
            key: 'spent_miles',
            class: 'spent-miles',
          },
          {
            title: 'Valor/1000',

            scopedSlots: { customRender: 'price_per_miles' },
          },
          {
            title: 'Preço Especial',
            scopedSlots: { customRender: 'special_price' },
          },
          {
            title: 'Preço Venda ',
            scopedSlots: { customRender: 'selling_price' },
          },
          {
            title: 'Validade',
            scopedSlots: { customRender: 'expires_at' },
          },
          {
            title: 'Data de cadastro',
            scopedSlots: { customRender: 'created' },
          },
          {
            title: '',
            align: 'right',
            fixed: 'right',
            scopedSlots: { customRender: 'action' },
            width: 190,
          },
        ]"
      >
        <template slot="id" slot-scope="record">
          {{ record.id }}
        </template>
        <template slot="supplier" slot-scope="record">
          {{ `${record.first_name} ${record.last_name}`.toUpperCase() }}
        </template>
        <template slot="quantity" slot-scope="record">
          {{ record.id }}
        </template>
        <template slot="spent_miles" slot-scope="record">
          {{ record.spent_miles }}
        </template>
        <template slot="price_per_miles" slot-scope="record">
          R$ {{ record.price_per_miles }}
        </template>
        <template slot="special_price" slot-scope="record">
          R$ {{ record.special_price }}
        </template>
        <template slot="selling_price" slot-scope="record">
          R$ {{ record.selling_price }}
        </template>
        <template slot="expires_at" slot-scope="record">
          {{ formatDate(record.expires_at) }}
        </template>
        <template slot="updated" slot-scope="record">
          {{ formatDateTime(record.updated) }}
        </template>
        <template slot="created" slot-scope="record">
          {{ formatDateTime(record.created) }}
        </template>
        <span class="table-actions" slot="action" slot-scope="milesCard">
          <a-tooltip placement="top" title="Ver">
            <a class="view" @click="viewMilesCard(milesCard.id, 'card')">
              <a-icon slot="prefix" type="eye-svg" />
            </a>
          </a-tooltip>

          <a-divider
            v-if="$root.isAdmin()"
            type="vertical"
            style="margin: 0 16px 0 12px"
          />

          <a-dropdown
            overlayClassName="actions-dropdown"
            v-if="$root.isAdmin()"
            :trigger="['click']"
          >
            <a style="font-size: 15px; color: #aaa">
              <i class="fas fa-ellipsis-v"></i>
            </a>

            <a-menu slot="overlay">
              <a-menu-item @click="openSpecialPriceModal(milesCard)">
                <a-icon type="dollar" /> Preço Especial
              </a-menu-item>

              <a-menu-item
                @click="$router.push(`/finances/payments/view/${milesCard.id}`)"
              >
                <a-icon type="calculator" /> Pagamentos
              </a-menu-item>

              <a-menu-item @click="view(milesCard.id)">
                <a-icon type="printer" /> Detalhamento
              </a-menu-item>

              <a-menu-item @click="viewMilesCard(milesCard.id, 'logs')">
                <a-icon type="profile" /> Histórico
              </a-menu-item>


            </a-menu>
          </a-dropdown>
        </span>
      </HayaTable>


    </div>
  </div>
</template>

<script>
import HayaTable from "@/components/general/HayaTable.vue";
import milesCardsMixin from "@/mixins/miles-cards/milesCardsMixin";
import suppliersMixins from "@/mixins/suppliersMixins";
import formatThings from "@/mixins/general/formatThings";

export default {
  mixins: [milesCardsMixin, suppliersMixins, formatThings],
  components: { HayaTable },
  data() {
    return {};
  },
  beforeMount() {
    document.title = "Relatório de Checkin - HAYA";
    this.getMilesCards();
  },
};
</script>

<style lang="scss" scoped></style>
