import _ from "lodash";

export default {
  data() {
    return {
      loading: false,
      suppliers: [],
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
      processing: false
    };
  },
  methods: {
    searchSupplier: _.debounce(function (e) {
      const serachText = e?.target?.value ? e.target.value : e;
      this.searchSuppliers(1, serachText);
    }, 400),
    changeSupplierPage(current) {
      this.supplier.pagination.page = current;
      this.getsupplier();
    },
    changeSupplierPageSize(current, pageSize) {
      this.supplier.pagination.page = current;
      this.supplier.pagination.perPage = pageSize;

      this.getsupplier();
    },
    onPageChange({
      current
    }) {
      this.getsupplier(current);

    },
    supplierTableChange(pagination, filters, sorter) {
      console.log("sorter s", sorter, pagination, filters);
      this.supplier.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.supplier.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getsupplier();
    },
    searchSuppliers(page, searchText) {
      this.loading = true;
      const params = {
        page,
        s: searchText,
        per_page: 30
      };
      this.$http
        .get("/supplier/search", {
          params
        })
        .then(
          ({
            data: {
              data,
              meta: {
                total
              }
            }
          }) => {
            this.pagination.total = total;
            this.suppliers = [...data];
          }
        )
        .catch((error) => {
          console.log(error);
          this.suppliers = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSupplier(id) {
      this.processing = true;
      this.$http
        .post("/supplier/details", {
          id
        })
        .then(({
          data
        }) => {
          delete data.user;
          this.$root.$emit("getSupplierRes", {
            ...data
          });
        })
        .catch(
          ({
            response: {
              data: {
                message
              }
            }
          }) => {
            this.$message.error(message);
          }
        )
        .finally(() => {
          this.processing = false;
        });
    }
  }
};